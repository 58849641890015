import React, { lazy, Suspense } from 'react';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import { PageLoader } from './layout/components/PageLoader';
import { LogoutPage } from './logout/components/LogoutPage';
import { ConfigurationPath, Path } from './paths';
import { validationOrdreDeTravailScheduleLoader } from './validationNext/loaders/ordreDeTravailScheduleLoader';
import { validationContractAgreementLoader } from './validationNext/loaders/contractAgreementLoader';
import { validationDailyTimesheetLoader } from './validationNext/loaders/timesheetLoader';
import { PrefacturationLoader } from './validationNext/loaders/PrefacturationLoader';
import { ValidationParams } from './validationNext/params';
import { PageNotFound } from './PageNotFound';
import { appQueryClient } from './RequestClients';
import {
  ContractAgreementDetailRouterId,
  ContractAgreementOutletRouterId,
} from './validationNext/components/contractAgreement/routeHelpers';
import { Can } from './authorization/Can';
import { configurationAbility } from './configuration/authorization/abilities';
import { contractAgreementLoader } from './contractAgreement/components/ContractAgreementDetailsPage/loader';
import { validationAbility } from './validationNext/authorization/abilities';
import { notificationsAbility } from './acknowledgements/authorization/abilities';
import { timesheetAbility } from './timesheet/authorization/abilities';
import { contractAgreementAbility } from './contractAgreement/authorization/abilities';
import { ForbiddenPage } from './ForbiddenPage';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const Loadable = (Component) => (props) => (
  <Suspense fallback={<PageLoader />}>
    <Component {...props} />
  </Suspense>
);

const GenerateDataPage = Loadable(
  lazy(() =>
    import('./utils/dev/GenerateDataPage').then((module) => ({
      default: module.GenerateDataPage,
    })),
  ),
);

const DispatchPage = Loadable(
  lazy(() =>
    import('./dispatch/components/DispatchPage').then((module) => ({
      default: module.DispatchPage,
    })),
  ),
);

const AcknowledgementsPage = Loadable(
  lazy(() =>
    import('./acknowledgements/components/AcknowledgementsPage/AcknowledgementsPage').then((module) => ({
      default: module.AcknowledgementsPage,
    })),
  ),
);
const ContractAgreementsPage = Loadable(
  lazy(() =>
    import('./contractAgreement/components/ContractAgreementsPage/ContractAgreementsPage').then((module) => ({
      default: module.ContractAgreementsPage,
    })),
  ),
);
const ContractAgreementDetailsPage = Loadable(
  lazy(() =>
    import('./contractAgreement/components/ContractAgreementDetailsPage/ContractAgreementDetailsPage').then((module) => ({
      default: module.ContractAgreementDetailsPage,
    })),
  ),
);
const LayoutPage = Loadable(
  lazy(() =>
    import('./layout/components/LayoutPage').then((module) => ({
      default: module.LayoutPage,
    })),
  ),
);
const UserSettingsPage = Loadable(
  lazy(() =>
    import('./userSettings/components/UserSettingsPage').then((module) => ({
      default: module.UserSettingsPage,
    })),
  ),
);
const PrivacyPolicyPage = Loadable(
  lazy(() =>
    import('./privacyPolicy/components/PrivacyPolicyPage').then((module) => ({
      default: module.PrivacyPolicyPage,
    })),
  ),
);
const TimesheetsPage = Loadable(
  lazy(() => import('./timesheet/components/TimesheetsPage/TimesheetsPage').then((module) => ({ default: module.TimesheetsPage }))),
);
const DailyTimeSheetDetailsPageNext = Loadable(
  lazy(() =>
    import('./dailyTimeSheet/components/DailyTimeSheetDetailsPage').then((module) => ({
      default: module.DailyTimeSheetDetailsPage,
    })),
  ),
);
const DailyTimeSheetDetailsPage = Loadable(
  lazy(() =>
    import('./timesheet/components/TimeSheetDetailsPage/DailyTimeSheetDetailsPage').then((module) => ({
      default: module.DailyTimeSheetDetailsPage,
    })),
  ),
);
const ValidationsPage = Loadable(
  lazy(() =>
    import('./validations/components/ValidationsPage/ValidationsBillingPage').then((module) => ({
      default: module.ValidationsBillingPage,
    })),
  ),
);

const ValidationNextPrefacturationDetailsPage = Loadable(
  lazy(() =>
    import('./validationNext/components/prefacturation/Prefacturation').then((module) => ({
      default: module.Prefacturation,
    })),
  ),
);
const ValidationNextDetailPage = Loadable(
  lazy(() =>
    import('./validationNext/components/ValidationDetailPage').then((module) => ({
      default: module.ValidationDetailPage,
    })),
  ),
);
const ValidationNextDetailPageContractAgreementOutlet = Loadable(
  lazy(() =>
    import('./validationNext/components/outlets/ValidationDetailContractAgreementOutlet').then((module) => ({
      default: module.ValidationDetailContractAgreementOutlet,
    })),
  ),
);
const ValidationNextDetailPageTimeSheetOutlet = Loadable(
  lazy(() =>
    import('./validationNext/components/outlets/ValidationDetailTimeSheetOutlet').then((module) => ({
      default: module.ValidationDetailTimeSheetOutlet,
    })),
  ),
);
const OrdreDeTravailPage = Loadable(
  lazy(() =>
    import('./ordreDeTravail/components/OrdresDeTravailPage/OrdresDeTravailPage').then((module) => ({
      default: module.OrdresDeTravailPage,
    })),
  ),
);
const OrdreDeTravailDetailsPage = Loadable(
  lazy(() =>
    import('./ordreDeTravail/components/OrdreDeTravailDetailsPage/OrdreDeTravailDetailsPage').then((module) => ({
      default: module.OrdreDeTravailDetailsPage,
    })),
  ),
);
const ConfigurationPage = Loadable(
  lazy(() =>
    import('./configuration/components/ConfigurationPage').then((module) => ({
      default: module.ConfigurationPage,
    })),
  ),
);
const IncompatibilityDetailsPage = Loadable(
  lazy(() =>
    import('./configuration/components/Incompatibility/IncompatibilityDetailsPage').then((module) => ({
      default: module.IncompatibilityDetailsPage,
    })),
  ),
);
const IncompatibilityCreationPage = Loadable(
  lazy(() =>
    import('./configuration/components/Incompatibility/IncompatibilityCreationPage').then((module) => ({
      default: module.IncompatibilityCreationPage,
    })),
  ),
);

export const getRoutes = (
  show_dispatch_routes: boolean,
  show_configuration_routes: boolean,
  show_generate_mock_data_route = false,
  show_daily_timesheet_next = false,
): RouteObject[] => {
  const children: RouteObject[] = [
    {
      element: (
        <Can I='view' a={notificationsAbility} passThrough>
          {(allowed) => (allowed ? <AcknowledgementsPage /> : <ForbiddenPage />)}
        </Can>
      ),
      path: Path.AcknowledgementsPage,
    },
    {
      element: <OrdreDeTravailDetailsPage />,
      path: `${Path.OrdresDeTravailPage}/:id`,
    },
    {
      element: <OrdreDeTravailPage />,
      path: Path.OrdresDeTravailPage,
    },
    {
      id: ContractAgreementDetailRouterId,
      element: (
        <Can I='view' a={contractAgreementAbility} passThrough>
          {(allowed) => (allowed ? <ContractAgreementDetailsPage /> : <ForbiddenPage />)}
        </Can>
      ),
      path: `${Path.ContractAgreementsPage}/:id`,
      loader: contractAgreementLoader(appQueryClient),
    },
    {
      element: (
        <Can I='view' a={contractAgreementAbility} passThrough>
          {(allowed) => (allowed ? <ContractAgreementsPage /> : <ForbiddenPage />)}
        </Can>
      ),
      path: Path.ContractAgreementsPage,
    },
    {
      element: (
        <Can I='view' a={timesheetAbility} passThrough>
          {(allowed) => (allowed ? <TimesheetsPage /> : <ForbiddenPage />)}
        </Can>
      ),
      path: Path.TimesheetsPage,
    },
    {
      element: show_daily_timesheet_next ? (
        <Can I='view' a={timesheetAbility} passThrough>
          {(allowed) => (allowed ? <DailyTimeSheetDetailsPageNext /> : <ForbiddenPage />)}
        </Can>
      ) : (
        <Can I='view' a={timesheetAbility} passThrough>
          {(allowed) => (allowed ? <DailyTimeSheetDetailsPage /> : <ForbiddenPage />)}
        </Can>
      ),
      path: `${Path.TimesheetsPage}/:id`,
    },
    {
      element: <UserSettingsPage />,
      path: Path.UserSettingsPage,
    },
    {
      element: <PrivacyPolicyPage />,
      path: Path.PrivacyPolicyPage,
    },
    {
      element: <LogoutPage />,
      path: Path.LogoutPage,
    },
    {
      path: Path.ValidationsPage,
      element: (
        <Can I='view' a={validationAbility} passThrough>
          {(allowed) => (allowed ? <ValidationsPage /> : <ForbiddenPage />)}
        </Can>
      ),
    },
    {
      element: (
        <Can I='view' a={validationAbility} passThrough>
          {(allowed) => (allowed ? <ValidationNextDetailPage /> : <ForbiddenPage />)}
        </Can>
      ),
      path: `${Path.ValidationsPage}/:${ValidationParams.ordreDeTravailId}`,
      loader: validationOrdreDeTravailScheduleLoader(appQueryClient),
      children: [
        {
          element: <ValidationNextPrefacturationDetailsPage />,
          path: `${Path.NextPrefacturationSuffix}`,
          loader: PrefacturationLoader(appQueryClient),
        },
        {
          id: ContractAgreementOutletRouterId,
          element: <ValidationNextDetailPageContractAgreementOutlet />,
          path: `${Path.ContractAgreementsPage}/:${ValidationParams.contractAgreementId}`,
          loader: validationContractAgreementLoader(appQueryClient),
          children: [
            {
              element: <ValidationNextDetailPageTimeSheetOutlet />,
              path: `${Path.TimesheetsPage}/:${ValidationParams.timesheetId}`,
              loader: validationDailyTimesheetLoader(appQueryClient),
            },
          ],
        },
      ],
    },
    {
      path: Path.ConfigurationPage,
      element: (
        <Can I='view' a={configurationAbility} passThrough>
          {(allowed) => (allowed && show_configuration_routes ? <Outlet /> : <ForbiddenPage />)}
        </Can>
      ),
      children: [
        {
          index: true,
          element: <Navigate to={ConfigurationPath.Incompatibilities} replace />,
        },
        {
          path: ConfigurationPath.Incompatibilities,
          element: <ConfigurationPage />,
        },
        {
          path: `${ConfigurationPath.Incompatibilities}/new`,
          element: <IncompatibilityCreationPage />,
        },
        {
          path: `${ConfigurationPath.Incompatibilities}/:id`,
          element: <IncompatibilityDetailsPage />,
        },
      ],
    },
    {
      element: show_dispatch_routes ? <DispatchPage /> : <PageNotFound />,
      path: `${Path.DispatchPage}/*`,
    },
    {
      element: show_generate_mock_data_route ? <GenerateDataPage /> : <PageNotFound />,
      path: `${Path.MockDataGeneratorPage}/*`,
    },
  ];

  return [
    {
      element: <LayoutPage />,
      path: '*',
      children,
    },
  ];
};
