import { AssignmentModel, EventModel, Model, ProjectModel, SchedulerResourceModel } from '@bryntum/schedulerpro';
import { components } from '../../generated/swagger-type';
import ExtendedEventModel from './ExtendedEventModel';
import ExtendedResourceModel from './ExtendedResourceModel';

export type EventRecord = components['schemas']['EventRecord'];
export type OverviewEventRecord = components['schemas']['OverviewEventRecord'];
export type OrdreDeTravailLockedJustificationLockedRecord = components['schemas']['OrdreDeTravailLockedJustificationLockedRecord'];
export type ClientRecord = components['schemas']['ClientRecordForDispatch'];
export type NestedEventRecord = components['schemas']['NestedEventRecord'];
export type AssignmentRecord = components['schemas']['AssignmentRecord'];
export type EquipmentDispatchResourceRecord = components['schemas']['EquipmentDispatchResourceRecord'];
export type CraneOperatorDispatchResourceRecord = components['schemas']['CraneOperatorDispatchResourceRecord'];
export type AccessoryDispatchResourceRecord = components['schemas']['AccessoryDispatchResourceRecord'];
export type AccessoryAssignmentRecord = components['schemas']['AccessoryAssignmentRecord'];
export type BranchRecord = components['schemas']['BranchRecord'];
export type FournisseurRecord = components['schemas']['FournisseurRecord'];
export type CraneOperator = components['schemas']['CraneOperatorRecord'];
export type SubcontractorRecord = components['schemas']['SubcontractorRecord'];
export type OrdreDeTravailStatus = components['schemas']['OrdreDeTravailStatus'];
export type ServiceCallStatus = components['schemas']['ServiceCallStatus'];
export type OrdreDeTravailType = components['schemas']['OrdreDeTravailType'];
export type EmployeeRecord = components['schemas']['EmployeeRecord'];
export type EmployeeCompetence = components['schemas']['EmployeeCompetence'];
export type EmployeeJobCode = components['schemas']['JobCode'];
export type EmployeeIncompatibilityRecord = components['schemas']['EmployeeIncompatibilityRecord'];
export type FetchEquipmentRecord = components['schemas']['FetchEquipmentRecord'];
export type EquipmentTypeRecord = components['schemas']['EquipmentTypeRecord'];
export type CraneOperatorAssignmentRecord = components['schemas']['CraneOperatorAssignmentRecord'];
export type TrainingRecord = components['schemas']['TrainingRecord'];
export type OptimizationMode = components['schemas']['OptimizationMode'];
export type EquipmentStatusRecord = components['schemas']['EquipmentStatusRecord'];
export type EquipmentStatusType = components['schemas']['EquipmentStatusType'];
export type EquipmentRecord = components['schemas']['EquipmentRecord'];
export type AccessoryRecord = components['schemas']['AccessoryRecord'];
export type AccessoryUpdateRecord = components['schemas']['AccessoryUpdateRecord'];
export type AccessoryTypeRecord = components['schemas']['AccessoryTypeRecord'];
export type AccessoryGroupRecord = components['schemas']['AccessoryGroupRecord'];
export type BillingCodeRecord = components['schemas']['BillingCodeRecord'];
export type OperatorRecord = components['schemas']['OperatorRecord'];
export type ReplacementOperatorRecord = components['schemas']['ReplacementOperatorRecord'];
export type DispatchResourceType = components['schemas']['DispatchResourceType'];
export type CraneOperatorStartingPoint = components['schemas']['CraneOperatorStartingPoint'];
export type CraneOperatorKind = components['schemas']['CraneOperatorKind'];
export type DispatchingAlertRecord = components['schemas']['DispatchingAlertRecord'];
export type DispatchingAlertSeverity = components['schemas']['DispatchingAlertSeverity'];
export type DispatchingAlertType = components['schemas']['DispatchingAlertType'];
export type CraneConfigurationRecord = components['schemas']['CraneConfigurationRecord'];
export type OrdreDeTravailLockedJustification = components['schemas']['OrdreDeTravailLockedJustification'];
export type CraneOperatorLockedJustification = components['schemas']['CraneOperatorLockedJustification'];
export type BasicEmployeeRecord = components['schemas']['BasicEmployeeRecord'];
export type DispatchingAlertArchivedJustification = components['schemas']['DispatchingAlertArchivedJustification'];
export type OrdreDeTravailPostponementReason = components['schemas']['PostponementReason'];
export type OrdreDeTravailPostponementRecord = components['schemas']['OrdreDeTravailPostponementRecord'];
export type DailyAccessoryReportType = components['schemas']['DailyAccessoryReportType'];
export type CancelReason = components['schemas']['CancelReason'];
export type OrdreDeTravailSendRequirementRecord = components['schemas']['OrdreDeTravailSendRequirementRecord'];
export type BooleanSendRequirementType = components['schemas']['BooleanSendRequirementType'];
export type CraneOperatorRecord = components['schemas']['CraneOperatorRecord'];
export type AccessoryEquipmentRecord = components['schemas']['AccessoryEquipmentRecord'];
export type ReplaceOrdreDeTravailEquipmentResponse = components['schemas']['ReplaceOrdreDeTravailEquipmentResponse'];

export enum BillingCodeCategory {
  Equipment = 1,
  Labor,
  MovingFees,
  Accessories,
  FixedFees,
  OtherFees,
}

export interface IEquipmentTypeRecord {
  equipmentType: EquipmentTypeRecord;
}

export interface IEquipmentResourceRecord {
  equipmentType: EquipmentTypeRecord;
  type: DispatchResourceType;
}

export interface IBranchRecord {
  branch: BranchRecord;
}

export interface IPrecedanceRankAndFullName {
  precedenceRank: number;
  fullName: string;
}

export const DispatchingAlertArchivedJustificationEnum: Record<
  Capitalize<DispatchingAlertArchivedJustification>,
  DispatchingAlertArchivedJustification
> = {
  TrainingWasCompleted: 'trainingWasCompleted',
  TrainingWillBeCompleted: 'trainingWillBeCompleted',
  TrainingNotRequired: 'trainingNotRequired',
  EmployeeAccompanied: 'employeeAccompanied',
  EquipmentMeetsNeed: 'equipmentMeetsNeed',
  InterchangeableEquipment: 'interchangeableEquipment',
  TonnageNotInContract: 'tonnageNotInContract',
  Custom: 'custom',
};

export const BooleanSendRequirementTypeEnum: Record<Capitalize<BooleanSendRequirementType>, BooleanSendRequirementType> = {
  AccessoryIdentification: 'accessoryIdentification',
  ContinuityConfirmed: 'continuityConfirmed',
  HasCraneOperator: 'hasCraneOperator',
  HasMainEquipment: 'hasMainEquipment',
  HasOperatorOrSubcontractor: 'hasOperatorOrSubcontractor',
  IsAssignedToVarious: 'isAssignedToVarious',
  ServiceCallStatus: 'serviceCallStatus',
  BlockingNotes: 'blockingNotes',
};

export const DispatchingAlertSeverityEnum: Record<Capitalize<DispatchingAlertSeverity>, DispatchingAlertSeverity> = {
  High: 'high',
  Low: 'low',
};

export const DispatchingAlertTypeEnum: Record<Capitalize<DispatchingAlertType>, DispatchingAlertType> = {
  ClientTraining: 'clientTraining',
  EquipmentTraining: 'equipmentTraining',
  WorkSiteTraining: 'workSiteTraining',
  MissingField: 'missingField',
  MissingDepartureDateTime: 'missingDepartureDateTime',
  CraneCapacity: 'craneCapacity',
  CraneFamily: 'craneFamily',
  EquipmentConflict: 'equipmentConflict',
  EquipmentRepairsStatus: 'equipmentRepairsStatus',
  EquipmentWorkSiteFixedStatus: 'equipmentWorkSiteFixedStatus',
  EquipmentWorkSiteMovableStatus: 'equipmentWorkSiteMovableStatus',
  EmployeeConflict: 'employeeConflict',
  EmployeeUnavailability: 'employeeUnavailability',
  Incompatibility: 'incompatibility',
  MissingBillingDocument: 'missingBillingDocument',
  AccessoryConflict: 'accessoryConflict',
  LinkedServiceCallNotAssigned: 'linkedServiceCallNotAssigned',
  SecondaryCraneConfigurationUsedOnMainCrane: 'secondaryCraneConfigurationUsedOnMainCrane',
};

export const OrdreDeTravailStatusEnum: Record<Capitalize<OrdreDeTravailStatus>, OrdreDeTravailStatus> = {
  Dispatching: 'dispatching',
  Upcoming: 'upcoming',
  Ongoing: 'ongoing',
  Cancelled: 'cancelled',
  Modified: 'modified',
  Finished: 'finished',
  External: 'external',
  Internal: 'internal',
};

export const ServiceCallStatusEnum: Record<Capitalize<ServiceCallStatus>, ServiceCallStatus> = {
  None: 'none',
  Delayed: 'default',
  Reserved: 'reserved',
  Transferred: 'transferred',
  Dispatched: 'dispatched',
  Canceled: 'canceled',
  Default: 'default',
  InModification: 'inModification',
};

export const DispatchResourceTypeEnum: Record<Capitalize<DispatchResourceType>, DispatchResourceType> = {
  Equipment: 'equipment',
  Subcontractor: 'subcontractor',
  Various: 'various',
  Operator: 'operator',
};

export const EmployeeCompetenceEnum: Record<Capitalize<EmployeeCompetence>, EmployeeCompetence> = {
  PasUnGrutier: 'pasUnGrutier',
  Apprenti1: 'apprenti1',
  Apprenti2: 'apprenti2',
  Apprenti3: 'apprenti3',
  Compagnon: 'compagnon',
  Exception: 'exception',
};

export const EmployeeJobCodeEnum: Record<Capitalize<EmployeeJobCode>, EmployeeJobCode> = {
  Administration: 'administration',
  Charpentier: 'charpentier',
  Chaudronnier: 'chaudronnier',
  Electricien: 'electricien',
  Grutier: 'grutier',
  GrutierClasseA: 'grutierClasseA',
  GrutierClasseADeuxiemeHomme: 'grutierClasseADeuxiemeHomme',
  GrutierClasseB: 'grutierClasseB',
  MecanicienAscenseur: 'mecanicienAscenseur',
  MecanicienChantier: 'mecanicienChantier',
  MecanicienMachineriesLourdes: 'mecanicienMachineriesLourdes',
  MonteurAcierStructure: 'monteurAcierStructure',
  OperateurEquipementLourd: 'operateurEquipementLourd',
};

export const OrdreDeTravailTypeEnum: Record<Capitalize<OrdreDeTravailType>, OrdreDeTravailType> = {
  OperatedHourly: 'operatedHourly',
  OperatedMonthly: 'operatedMonthly',
  Bare: 'bare',
  AccessoryRental: 'accessoryRental',
  RollingEquipment: 'rollingEquipment',
  LiftingPlan: 'liftingPlan',
  LiftingTest: 'liftingTest',
  LaborRentalWithCrane: 'laborRentalWithCrane',
  LaborRental: 'laborRental',
  CraneOperatorLaborRental: 'craneOperatorLaborRental',
  Storage: 'storage',
};

export const OptimizationModeEnum: Record<Capitalize<OptimizationMode>, OptimizationMode> = {
  SingleBranch: 'singleBranch',
  InterBranch: 'interBranch',
};

export const CraneOperatorStartingPointEnum: Record<Capitalize<CraneOperatorStartingPoint>, CraneOperatorStartingPoint> = {
  Branch: 'branch',
  WorkSite: 'workSite',
};

export const EquipmentStatusTypeEnum: Record<Capitalize<EquipmentStatusType>, EquipmentStatusType> = {
  WorkSiteFixed: 'workSiteFixed',
  WorkSiteMovable: 'workSiteMovable',
  Repairs: 'repairs',
};

export const DailyAccessoryReportEnum: Record<Capitalize<DailyAccessoryReportType>, DailyAccessoryReportType> = {
  CraneAndAccessories: 'craneAndAccessories',
  Accessories: 'accessories',
};

export const CraneOperatorKindEnum: Record<Capitalize<CraneOperatorKind>, CraneOperatorKind> = {
  AdditionalMan: 'additionalMan',
  Electrician: 'electrician',
  Engineer: 'engineer',
  Helper: 'helper',
  IndustrialDesigner: 'industrialDesigner',
  Mechanic: 'mechanic',
  Operator: 'operator',
  ReplacedOperator: 'replacedOperator',
  Rigger: 'rigger',
  Signalman: 'signalman',
  SiteSupervisor: 'siteSupervisor',
  TechnicalSupervisor: 'technicalSupervisor',
  Trucker: 'trucker',
  Welder: 'welder',
};

export const CraneOperatorKindNumberEnum: Record<CraneOperatorKind, number> = {
  operator: 1,
  helper: 2,
  rigger: 3,
  mechanic: 4,
  electrician: 5,
  trucker: 6,
  welder: 7,
  signalman: 8,
  additionalMan: 9,
  siteSupervisor: 10,
  industrialDesigner: 21,
  engineer: 22,
  technicalSupervisor: 23,
  replacedOperator: 99,
};

export const OrdreDeTravailLockedJustificationEnum: Record<
  Capitalize<OrdreDeTravailLockedJustification>,
  OrdreDeTravailLockedJustification
> = {
  CreateFromDto: 'createFromDto',
  SetStatusAsUpcoming: 'setStatusAsUpcoming',
  SetStatusAsInternal: 'setStatusAsInternal',
  SetStatusAsExternal: 'setStatusAsExternal',
  NotStandardOrdreDeTravail: 'notStandardOrdreDeTravail',
  CreateRelatedOrdresDeTravail: 'createRelatedOrdresDeTravail',
  SecondServing: 'secondServing',
};

export const OrdreDeTravailPostponementReasonEnum: Record<
  Capitalize<OrdreDeTravailPostponementReason>,
  OrdreDeTravailPostponementReason
> = {
  ClientEquipmentBreakdown: 'clientEquipmentBreakdown',
  ClientMechanicalBreakdown: 'clientMechanicalBreakdown',
  ClientUnknownReason: 'clientUnknownReason',
  ClientRepresentativeError: 'clientRepresentativeError',
  ClientEquipmentShortage: 'clientEquipmentShortage',
  ClientLaborShortage: 'clientLaborShortage',
  ClientCraneShortage: 'clientCraneShortage',
  ClientIncorrectDate: 'clientIncorrectDate',
  ClientWrongCrane: 'clientWrongCrane',
  ClientDidNotReceiveOccupancyPermit: 'clientDidNotReceiveOccupancyPermit',
  ClientWeather: 'clientWeather',
  ClientTransportation: 'clientTransportation',
  GuayEquipmentBreakdown: 'guayEquipmentBreakdown',
  GuayEquipmentShortage: 'guayEquipmentShortage',
  GuayLaborShortage: 'guayLaborShortage',
};

export const CancelReasonEnum: Record<Capitalize<CancelReason>, CancelReason> = {
  ClientEquipmentBreakdown: 'clientEquipmentBreakdown',
  ClientMechanicalBreakdown: 'clientMechanicalBreakdown',
  ClientUnknownReason: 'clientUnknownReason',
  ClientRepresentativeError: 'clientRepresentativeError',
  ClientEquipmentShortage: 'clientEquipmentShortage',
  ClientLaborShortage: 'clientLaborShortage',
  ClientCraneShortage: 'clientCraneShortage',
  ClientIncorrectDate: 'clientIncorrectDate',
  ClientWrongCrane: 'clientWrongCrane',
  ClientDidNotReceiveOccupancyPermit: 'clientDidNotReceiveOccupancyPermit',
  ClientWeather: 'clientWeather',
  ClientTransportation: 'clientTransportation',
  GuayEquipmentBreakdown: 'guayEquipmentBreakdown',
  GuayEquipmentShortage: 'guayEquipmentShortage',
  GuayLaborShortage: 'guayLaborShortage',
};

export type DispatchProjectModel = ProjectModel & {
  cancelReason: CancelReason | undefined;
  updateContinuity: boolean | undefined;
};

export type DispatchEventModel = EventRecord & ExtendedEventModel;
export type OverviewEventModel = OverviewEventRecord & EventModel;
export type OptimizationEventModel = OptimizationEventRecord & EventModel;
export type DispatchResourceModel = SchedulerResourceModel &
  ExtendedResourceModel & {
    branch: BranchRecord;
    type: DispatchResourceType;
  };
export type AccessoryDispatchResourceModel = AccessoryDispatchResourceRecord & DispatchResourceModel;
export type EquipmentResourceModel = EquipmentDispatchResourceRecord & DispatchResourceModel;
export type OperatorResourceModel = CraneOperatorDispatchResourceRecord & DispatchResourceModel;
export type DispatchAssignmentModel = AssignmentRecord & AssignmentModel;
export type EmployeeModel = EmployeeRecord & Model;
export type CraneModel = FetchEquipmentRecord & Model;
export type OrdreDeTravailMessageDataModel = {
  eventRecord: DispatchEventModel;
  craneAssignment: DispatchAssignmentModel;
  craneOperatorsAssignments: DispatchAssignmentModel[];
  equipmentDispatchResource: DispatchResourceModel;
  craneOperatorDispatchResource: DispatchResourceModel;
  accessoryAssignments: AccessoryAssignmentRecord[];
  accessoryDispatchResources: AccessoryDispatchResourceRecord[];
};
export type CraneOperatorAssignmentModel = CraneOperatorAssignmentRecord & Model;
export type FetchEquipmentResponse = components['schemas']['FetchEquipmentRecordFetchResponse'];
export type FetchEmployeesResponse = components['schemas']['EmployeeRecordFetchResponse'];

export type OptimizationStatus = components['schemas']['OptimizationStatus'];
export type OptimizationRecord = components['schemas']['OptimizationRecord'];
export type OptimizationEventRecord = components['schemas']['OptimizationEventRecord'];
export const OptimizationStatusEnum: Record<Capitalize<OptimizationStatus>, OptimizationStatus> = {
  InProgress: 'inProgress',
  Completed: 'completed',
  ResultReceived: 'resultReceived',
  Cancelled: 'cancelled',
};
export type OptimizationAppliedMessageDataModel = {
  branchIds: string[];
  date: string;
};

export type SyncSubcontractorRecord = components['schemas']['SyncSubcontractorRecord'];
export type ContinuityInformationRecord = components['schemas']['ContinuityInformationRecord'];
export type ExtendOrdreDeTravailRequestBody = components['schemas']['ExtendOrdreDeTravailRequestBody'];
export type PostponeOrdreDeTravailRequestBody = components['schemas']['PostponeOrdreDeTravailRequestBody'];
export type AdvanceOrdreDeTravailRequestBody = components['schemas']['AdvanceOrdreDeTravailRequestBody'];
export type SubcontractOrdreDeTravailRequestBody = components['schemas']['SubcontractOrdreDeTravailRequestBody'];
export type UnassignAllOrdreDeTravailRequestBody = components['schemas']['UnassignAllBody'];
export type PlanOrdreDeTravailFournisseurRequestBody = components['schemas']['PlanOrdreDeTravailFournisseurRequestBody'];
export type AddOperatorRequestBody = components['schemas']['AddOperatorRequestBody'];
export type EditGeneralNotesBody = components['schemas']['EditGeneralNotesBody'];
export type ArchiveAlertRequestBody = components['schemas']['ArchiveAlertRequestBody'];
export type ApproveOptimizationRequestBody = components['schemas']['ApproveOptimizationRequestBody'];
export type ValidateTimeSheetNumberResponse = components['schemas']['ValidateTimeSheetNumberResponse'];
export type ContinuityUpdateType = components['schemas']['ContinuityUpdateType'];
export const ContinuityUpdateTypeEnum: Record<Capitalize<ContinuityUpdateType>, ContinuityUpdateType> = {
  All: 'all',
  AllExceptLast: 'allExceptLast',
  None: 'none',
};

export type DispatchingAlertCreatedRecord = {
  serviceCallId: string;
  ordreDeTravailId: string;
  type: components['schemas']['DispatchingAlertType'];
  severity: components['schemas']['DispatchingAlertSeverity'];
  frenchMessage: string;
  englishMessage: string;
  date: string;
  statusType?: components['schemas']['EquipmentStatusType'];
};

export type DispatchRecord = components['schemas']['GetDispatchRecord'];
