export const dispatchHubName = 'dispatch';
export const ordreDeTravailCreatedOrUpdatedMessageName = 'created-or-updated-ordre-de-travail';
export const ordreDeTravailAlertsUpdatedMessageName = 'ordre-de-travail-alerts-updated';
export const ordreDeTravailDeletedMessageName = 'deleted-ordre-de-travail';
export const ordreDeTravailOptimizationCreatedOrUpdatedMessageName = 'created-or-updated-ordre-de-travail-optimization';
export const dispatchingAlertCreatedMessageName = 'dispatching-alert-created';
export const equipmentCreatedOrUpdatedMessageName = 'created-or-updated-equipment';
export const employeeCreatedOrUpdatedMessageName = 'created-or-updated-employee';
export const optimizationAppliedMessageName = 'optimization-applied';
export const enum EquipmentTypeCode {
  telescopicSelfPropelled = 2,
  conventionalCrawler = 5,
  rollingStock = 9,
  craneEquipmentGroupCode = 20,
}
